@use '@assuranceiq/react-components/styles/colors';
@use 'styles/config/spacing';
@use 'styles/config/typography';

.disclosureContainer {
  position: relative;
}

.disclosure {
  display: flex;
  flex-direction: column;
  max-height: typography.$font-size-base * 12;
  position: relative;
  border-radius: spacing.$unit;
  border: 1px solid colors.$gray-50;
  padding: spacing.$unit * 2.5;

  ::-webkit-scrollbar {
    width: 6px;
    height: 166px;
  }

  ::-webkit-scrollbar-track {
    border-radius: spacing.$unit * 1.25;
    background: colors.$gray-50;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: spacing.$unit * 1.25;
    background-color: colors.$gray-75;
  }
}

.checkbox {
  font-weight: typography.$font-weight-bold;
}

.tcpaConsent {
  overflow-y: scroll;
  text-align: left;
  padding-right: spacing.$unit * 0.75;
}

.scrollToEnd {
  bottom: spacing.$unit * 1.25;
  font-size: typography.$font-size-small;
  min-width: typography.$font-size-small * 10;
  min-height: typography.$font-size-small * 3;

  .arrowDown {
    transform: rotate(90deg);
    font-size: typography.$font-size-small;
    width: typography.$font-size-small * 1.5;
  }
}

.scrollButton {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
