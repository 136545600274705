@use '@assuranceiq/react-components/styles/colors';

@use 'styles/config/spacing';
@use 'styles/config/typography';
@use 'styles/config/breakpoints';

.superText {
  font-size: typography.$font-size-small;
}

.agreement {
  font-size: typography.$font-size-small;
  font-weight: typography.$font-weight-light;
  margin-bottom: spacing.$unit * 0.5;
  margin-top: spacing.$unit * 0.5;
}

.disclosurePaper {
  position: absolute;
  max-width: 279px;

  @include breakpoints.sm {
    max-width: 367px;
  }

  @include breakpoints.md {
    max-width: 440px;
  }
}

.disclosure {
  padding-top: spacing.$unit;

  font-size: typography.$font-size-tiny;
  font-weight: typography.$font-weight-normal;
  line-height: typography.$line-height-small;

  text-align: left;

  @include breakpoints.md {
    padding-top: spacing.$unit * 2;
  }

  a {
    color: colors.$primary;
  }

  b:not(:first-child) {
    display: inline-block;
    margin-top: spacing.$unit * 2;
    font-weight: typography.$font-weight-bold;
  }
}
