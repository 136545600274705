@use '@assuranceiq/react-components/styles/colors';

@use 'styles/config/breakpoints';
@use 'styles/config/proj-colors';
@use 'styles/config/spacing';
@use 'styles/config/typography';

$call-agent-link-color: colors.$active;
$call-agent-icon-size: 18px;

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: proj-colors.$gray-82;

  &.branded {
    background-color: proj-colors.$branded-bg;

    @include breakpoints.lg {
      background-blend-mode: soft-light;
      flex-shrink: 0;
      background-image: url(../../../public/images/logo-bg-left.svg),
        url(../../../public/images/logo-bg-right.svg);
      background-size: 1541px 1333px;
      background-repeat: no-repeat;
      background-position: -733px -700px, calc(100vw - 733px) -700px;
    }
  }

  &.resultPageRoot {
    background-color: inherit;
  }
}

.container {
  padding: spacing.$unit * 3 spacing.$unit * 4 spacing.$unit * 7 spacing.$unit * 4;
  background-color: colors.$gray-25;
  border-radius: 20px 20px 0 0;

  @include breakpoints.tiny-only {
    padding: spacing.$unit;
  }
}

.resultPageContainer {
  padding: 0;
  border-radius: 0;
}

.subPlateText {
  text-align: center;
}

.card {
  background: colors.$white;
  padding: 2em;
  box-shadow: proj-colors.$card-box-shadow;
  border-radius: 5px;

  @include breakpoints.lg {
    width: 600px;
    margin: 0 25%;
  }
}

.resultPageCard {
  background: colors.$white;
  padding: typography.$font-size-base;
  border-radius: 0;

  @include breakpoints.md {
    margin: 0 15%;
  }
}

.progressBar {
  margin: spacing.$unit auto spacing.$unit * 4 auto;

  @include breakpoints.xs-only {
    margin-bottom: spacing.$unit * 3;
  }

  @include breakpoints.sm-only {
    margin-bottom: spacing.$unit * 3;
  }
}

.phoneSubFooter {
  text-align: center;
  color: colors.$black;
  font-size: typography.$font-size-tiny;
  line-height: typography.$line-height-small;
  padding: 0;
  margin: spacing.$unit * 2 0 0;

  @include breakpoints.sm {
    font-size: typography.$font-size-base;
    line-height: typography.$line-height-medium;
    margin: spacing.$unit * 4 0 0;
  }

  .textWithNumber {
    font-weight: typography.$font-weight-bold;

    a,
    span {
      color: colors.$primary;
      text-decoration-line: underline;
      text-decoration-skip-ink: none;
      text-decoration-thickness: 1px;
      text-underline-offset: 2px;
      vertical-align: baseline;
      background: none;
      min-height: auto;
      letter-spacing: 0.24px;
      font-size: typography.$font-size-tiny;
      line-height: typography.$line-height-small;

      @include breakpoints.sm {
        color: colors.$black;
        text-decoration-line: none;
        font-size: typography.$font-size-base;
        line-height: typography.$line-height-medium;
        padding: 0 spacing.$unit;
      }
    }
  }
}

.greenBanner {
  background: colors.$green-25;
  color: colors.$green-100;
  padding: spacing.$unit * 2;
  border-radius: 5px;
  margin-bottom: spacing.$unit * 2.5;

  @include breakpoints.lg {
    width: 600px;
    margin: 0 25% 2% 25%;
  }
  @include breakpoints.xs-only {
    padding: spacing.$unit * 1.5;
  }
  @include breakpoints.tiny-only {
    padding: spacing.$unit * 1.2;
    margin-bottom: spacing.$unit * 1.2;
  }
  p {
    margin: 0;
  }
}
