@use '@assuranceiq/react-components/styles/colors';

@use 'styles/config/spacing';
@use 'styles/config/typography';

$option-border-radius: 5px;
$error-icon-size: typography.$font-size-base;

.root {
  padding: spacing.$unit;
  margin: 0;
  border-bottom: 1px solid colors.$gray-50;

  &:last-child {
    border-bottom: none;
  }

  &:focus-within {
    outline: 1px solid colors.$active-light;
    outline-offset: 1px;
  }

  :global .MuiTypography-root {
    width: 100%;
  }

  :global .PrivateSwitchBase-root {
    color: colors.$blue-100;
  }
}

.error {
  border-color: colors.$error;
  background-color: colors.$error-light-accent;
  color: colors.$error;

  &:hover {
    border-color: colors.$error;
  }
}

.checked {
  border-color: colors.$active-light;
  background-color: colors.$active-light-accent;
}

.label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.labelText {
  color: colors.$text-color;
}

.errorIcon {
  width: $error-icon-size;
  height: $error-icon-size;
  margin: spacing.$unit * 1.5;
}
