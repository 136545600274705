@use '@assuranceiq/react-components/styles/colors';

@use 'styles/config/spacing';
@use 'styles/config/typography';
@use 'styles/config/breakpoints';

.root {
  background-color: colors.$white;
  box-shadow: none;
  position: relative;

  &.resultPageRoot {
    border-bottom: 1px solid colors.$gray-50;
  }

  .top {
    padding: spacing.$unit * 2;
    display: grid;
    grid-template-areas: 'logo call-button';
    grid-template-columns: 1.2fr 1fr;
    grid-gap: 0;
    align-items: center;

    @include breakpoints.xs-only {
      padding: spacing.$unit;
    }
  }

  .headerRightBlock {
    text-align: right;
    color: colors.$gray-75;
    line-height: typography.$line-height-large;

    .headerBannerText {
      font-size: typography.$font-size-small;
      line-height: 120%;
    }

    .callLink {
      font-size: typography.$font-size-x-large;
      line-height: 120%;
      color: initial;

      span {
        color: colors.$blue-100;
        text-decoration-line: none;
        font-weight: typography.$font-weight-bold;

        &:hover {
          cursor: inherit;
        }
      }
    }
  }

  .brandLogo {
    grid-area: logo;
    max-width: 50vw;
    max-height: 40px;

    @include breakpoints.lg {
      max-width: 300px;
      max-height: 60px;
    }
  }

  .callButton {
    grid-area: call-button;
    font-size: typography.$font-size-base;
    font-weight: typography.$font-weight-normal;
    line-height: typography.$line-height-large;
    min-height: auto;
    border-radius: spacing.$unit * 6;
    padding: spacing.$unit / 2 spacing.$unit * 2;
    color: colors.$white;
    border: 1px solid transparent;
    text-align: right;

    :global .MuiButton-startIcon {
      margin-right: 6px;
      width: spacing.$unit * 2.5;
      fill: colors.$white;
    }
  }

  .disclosure {
    display: flex;
    justify-content: right;
    margin-top: spacing.$unit;
    font-family: 'Open Sans', sans-serif;
    color: colors.$text-color;
    text-decoration-color: colors.$text-color;
    font-size: typography.$font-size-tiny;
    line-height: typography.$line-height-small;
    font-weight: typography.$font-weight-normal;

    &:hover {
      text-decoration: none;
    }
  }
}
