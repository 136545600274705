@use '@assuranceiq/react-components/styles/colors';
@use 'styles/config/breakpoints';
@use 'styles/config/proj-colors';
@use 'styles/config/spacing';
@use './simpleForm';

.root {
  text-align: center;
}

.inputContainer {
  margin-bottom: spacing.$unit * 3;
}

.nextButtonContainer {
  @include breakpoints.md-down {
    background-color: colors.$bg-paper;
  }

  .nextButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
}

.nextButtonContainerBottom {
  @include breakpoints.md-down {
    position: fixed;
    width: 100vw;
    left: 0;
    bottom: 0;
    height: simpleForm.$next-button-container-height;
    box-shadow: 0 2px 12px proj-colors.$shadow;
    background-color: colors.$bg-paper;
    will-change: bottom;
    padding: 2 * spacing.$unit;
    z-index: 3;
  }

  .nextButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
}
