@use '@assuranceiq/react-components/styles/colors';

@use 'styles/config/proj-colors';
@use 'styles/config/spacing';

$label-flex-basis: 38%;
$border-radius: calc(spacing.$unit / 2);
$border-color: colors.$border-light;
$border-focused-color: colors.$active;
$border-hovered-color: colors.$text-color;
$border-error-color: colors.$error;
$border-valid-color: colors.$info;
$border-style: 1px solid $border-color;

.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.hovered {
  .container {
    border-color: $border-hovered-color;

    &.containerNoLabel {
      border-color: $border-hovered-color;
    }
  }
}

.focused {
  box-shadow: proj-colors.$focused-input-box-shadow;

  .container {
    border-color: $border-focused-color;

    &.containerNoLabel {
      border-color: $border-focused-color;
    }
  }
}

.label {
  flex-basis: $label-flex-basis;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding: 0 spacing.$unit 0 spacing.$unit * 2;

  color: proj-colors.$inverted-input-text-color;
  background-color: proj-colors.$inverted-input-bg-color;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.container {
  flex-basis: 100 - $label-flex-basis;
  display: flex;
  align-items: stretch;
  border: $border-style;
  border-left: none;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  overflow: hidden;

  &.containerNoLabel {
    flex-basis: 100%;
    border-left: $border-style;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  &.errorContainer {
    border-color: $border-error-color;
  }
}

.valid {
  .container {
    border-color: $border-valid-color;
  }
}
