@use '@assuranceiq/react-components/styles/colors';

@use 'styles/config/spacing';
@use 'styles/config/typography';
@use 'styles/config/breakpoints';

.root {
  width: 100%;
  font-size: typography.$font-size-tiny;
  background-color: colors.$bg-default;

  margin: 0 auto;
  // fill up the rest of the page till the bottom
  box-shadow: 0 50vh 0 50vh colors.$bg-light;
  border-top: 1px solid colors.$gray-50;
  color: colors.$gray-75;

  :global .MuiContainer-root {
    background-color: colors.$bg-light;
    padding: spacing.$unit * 5 spacing.$unit * 4 spacing.$unit;

    @include breakpoints.xs-only {
      padding-top: spacing.$unit * 3;
    }
  }

  a {
    white-space: nowrap;
    color: colors.$primary;
    text-decoration-line: underline;

    @include breakpoints.xs-only {
      letter-spacing: normal;
    }
  }

  p {
    margin-bottom: spacing.$unit;
    letter-spacing: 0.12px;

    @include breakpoints.xs-only {
      letter-spacing: normal;
    }
  }

  .callAgent {
    margin-bottom: spacing.$unit * 2;
    font-size: typography.$font-size-small;
    line-height: typography.$line-height-medium;
    text-align: center;

    .title {
      color: initial;
      margin-bottom: spacing.$unit * 2;
    }

    .number {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: typography.$font-size-large;
      font-weight: typography.$font-weight-semi-bold;
      margin-bottom: spacing.$unit / 2;
      letter-spacing: 0.24px;
      color: colors.$text-color;
      line-height: typography.$line-height-x-large;

      a {
        color: inherit;
        text-decoration-line: none;
        background: none;
        font-size: inherit;
        font-weight: inherit;
        min-height: auto;
        letter-spacing: 0.24px;
        align-self: center;

        :global .MuiButton-startIcon {
          width: spacing.$unit * 2.5;
          height: spacing.$unit * 2.5;
          margin-right: spacing.$unit / 2;
          margin-top: spacing.$unit / 2;
        }

        @include breakpoints.sm {
          text-decoration-line: none;
          color: colors.$text-color;
        }

        @include breakpoints.md {
          cursor: text;
        }
        @include breakpoints.xs-only {
          font-size: typography.$font-size-x-large;
        }
      }
    }

    .callHours {
      text-align: center;
      font-size: typography.$font-size-small;

      ul {
        padding: 0;
        margin: 0;

        li {
          display: inline-block;
          letter-spacing: 0.16px;

          @include breakpoints.xs-only {
            display: block;
          }

          &:after {
            content: '|';
            margin: 0 spacing.$unit;

            @include breakpoints.xs-only {
              content: '';
              margin: 0;
            }
          }

          &:last-child:after {
            content: '';
          }

          @include breakpoints.xs-only {
            letter-spacing: normal;
          }
        }
      }
    }
  }

  .links {
    display: flex;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.16px;
    font-size: typography.$font-size-tiny;

    ul {
      display: inline-block;
      list-style: none;
      padding-left: 0;
      padding-bottom: spacing.$unit * 3;
      margin-top: 0;
      margin-bottom: spacing.$unit * 3;
      line-height: typography.$line-height-large;
      border-bottom: 1px solid colors.$gray-50;

      li {
        display: inline-block;
        letter-spacing: 0.16px;

        &:after {
          content: '|';
          margin-left: spacing.$unit;
        }

        &:last-child:after {
          content: '';
        }
      }
    }
  }

  .widgets {
    display: flex;
    justify-content: center;
    gap: spacing.$unit;
    margin-bottom: spacing.$unit * 3;
  }

  &.resultPageRoot {
    background-color: colors.$gray-25;
  }
}
