@use '@assuranceiq/react-components/styles/colors';
@use './color-fns';

$shadow: color-fns.black(0.16);
$card-shadow: change-color(colors.$gray-60, $alpha: 0.16);
$card-box-shadow: 0px 2px 4px 0px $card-shadow, 0px 2px 12px 0px $card-shadow,
  0px 6px 8px 0px $card-shadow;
$dark-background-text-color: #ffffff;

$inverted-input-text-color: colors.$white;
$inverted-input-bg-color: colors.$bg-dark;
$gray-10: #fafcff;
$gray-45: #939393;
$gray-82: #525252;
$gray-70: #5d6c77;
$gray-75: #637582;
$info-block-background: #f4f9f0;
$branded-bg: #005d92;

$focused-input-box-shadow: 0px 2px 4px 0px rgba(163, 181, 194, 0.16),
  0px 2px 12px 0px rgba(163, 181, 194, 0.16), 0px 6px 8px 0px rgba(163, 181, 194, 0.16);

:export {
  focused-input-box-shadow: $focused-input-box-shadow;
  gray45: $gray-45;
  gray-70: $gray-70;
}
