@use '@assuranceiq/react-components/styles/colors';

@use 'styles/config/spacing';
@use 'styles/config/typography';

.banner {
  width: 100%;
  background: colors.$bg-light;
  color: colors.$text-color;
  display: flex;
  font-size: typography.$font-size-tiny;
  justify-content: center;
  align-items: center;
  gap: calc(spacing.$unit/2);

  .desc {
    display: none;
  }

  .indicator:before {
    content: '⬤';
    color: colors.$success-light;
    line-height: normal;
  }
}
