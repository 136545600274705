@use '@assuranceiq/react-components/styles/colors';

@use 'styles/config/proj-colors';
@use 'styles/config/breakpoints';
@use 'styles/config/spacing';
@use 'styles/config/typography';

$progress-height: spacing.$unit;
$progress-bar-background-color: colors.$gray-60;
$progress-bar-progress-color: colors.$green-100;
$back-button-icon-size: spacing.$unit * 2;

:export {
  backButtonIconArrowColor: colors.$text-muted;
}

.barContainer {
  display: flex;
  width: 100%;
  max-width: 404px;
  justify-content: center;
  align-items: center;
  margin-bottom: spacing.$unit * 2 - 1;
  flex-direction: row;

  &.barContainerMobile {
    flex-direction: column;
    .backButtonWrapper {
      margin-left: -6px;
    }
  }

  .progressText {
    padding-left: spacing.$unit * 2;
    color: colors.$gray-100;
    text-align: center;
    font-style: normal;
    font-weight: typography.$font-weight-bold;
  }

  .backButtonWrapper {
    display: flex;
    margin-right: spacing.$unit * 2;
    color: colors.$blue-100;
    text-align: center;
    font-style: normal;
    text-decoration-color: colors.$blue-100;
    text-underline-offset: spacing.$unit - 6;
    align-items: center;

    svg.backButtonIcon {
      stroke: colors.$blue-100;
      stroke-width: 1;
    }
    p.backButtonText {
      font-size: $back-button-icon-size;
      font-weight: typography.$font-weight-bold;
      margin-left: -3px;
      @media (min-width: breakpoints.$sm) {
        font-size: $back-button-icon-size + 2;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .progressGuidelineWrapper {
    justify-content: space-between;
    display: flex;
    width: 100%;
    padding-bottom: spacing.$unit - 2;
  }

  .startText {
    color: proj-colors.$gray-70;

    text-align: center;
    font-size: spacing.$unit * 2;
    font-style: normal;
    font-weight: typography.$font-weight-bold;
    margin-right: spacing.$unit * 2;
    @media (min-width: breakpoints.$sm) {
      font-size: spacing.$unit * 2 + 2;
    }
  }
}

.progressBar {
  width: 100%;
  background-color: $progress-bar-background-color;
  height: $progress-height;
  border-radius: $progress-height * 0.5;

  :global .MuiLinearProgress-bar {
    border-radius: $progress-height * 0.5;
    background-color: $progress-bar-progress-color;
  }

  @media (min-width: breakpoints.$sm) {
    height: $progress-height + 3;
  }
}
