@use '@assuranceiq/react-components/styles/colors';
@use 'styles/config/spacing';
@use 'styles/config/breakpoints';
@use 'styles/config/typography';
@use 'components/SimpleForm/Input/input';

.root {
  text-align: left;
  margin-bottom: spacing.$unit * 1.5;

  max-width: input.$max-width;
  &:last-child {
    margin-bottom: 0;
  }
}

.input {
  color: rgba(0, 0, 0, 0.78);
  font-weight: typography.$font-weight-bold;
  font-size: typography.$font-size-large;
}

.placeholder {
  color: colors.$gray-60;
  font-size: typography.$font-size-base;
  font-weight: typography.$font-weight-normal;
}

.error {
  color: colors.$error;
}

.menu {
  height: 30vh;
  @include breakpoints.lg {
    height: 60vh;
  }
}

.arrowDown {
  transform: rotate(90deg);
  color: colors.$blue-100;
  width: spacing.$unit * 3.125;
  height: spacing.$unit * 3;
  margin-right: spacing.$unit * 2.25;

  // make arrow clickable
  position: absolute;
  right: 0;
  pointer-events: none;
}
