@use '@assuranceiq/react-components/styles/colors';
@use 'styles/config/typography';
@use 'styles/config/breakpoints';
@use 'styles/config/spacing';

$underline-offset: 2px;

// TODO: Should be added as another variant of the button in MUI theme
@mixin underlined {
  min-height: fit-content;
  border: none;
  font-size: typography.$font-size-base;
  font-weight: typography.$font-weight-bold;
  text-decoration: underline;
  text-decoration-color: colors.$blue-100;
  text-underline-offset: $underline-offset;
  background: none;

  &:hover,
  &:focus,
  &:active {
    background: none;
    text-decoration: underline;
    border: none;
    color: colors.$blue-125;
    text-decoration-color: colors.$blue-125;
  }

  @include breakpoints.sm {
    font-size: typography.$font-size-large;
  }

  :global .MuiButton-startIcon,
  :global .MuiButton-endIcon {
    stroke: colors.$blue-100;
    margin-right: 0;
    margin-left: 0;

    svg {
      font-size: typography.$font-size-xx-large;
    }
  }
}
