@use '@assuranceiq/react-components/styles/colors';

@use 'styles/config/typography';

.dollarSign {
  font-size: typography.$font-size-large;
  font-weight: typography.$font-weight-bold;
}

:global .Mui-error {
  :local .dollarSign {
    color: colors.$error;
  }
}
