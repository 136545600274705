// https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
.grecaptcha-badge {
  visibility: hidden;
}

// HACK: Fix scroll issue (white space at the bottom of the page) due to an img loaded by
// GoogleTagManager
img[height='1'][width='1'] {
  display: none;
}
