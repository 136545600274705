@use '@assuranceiq/react-components/styles/colors';
@use 'styles/config/spacing';
@use 'styles/config/typography';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
}

.row {
  width: 288px;
  font-weight: typography.$font-weight-semi-bold;
  padding: spacing.$unit * 2 0 spacing.$unit * 2 0;
  border-bottom: 1px solid colors.$gray-50;
}
