@use '@assuranceiq/react-components/styles/colors';

@use 'styles/config/breakpoints';
@use 'styles/config/proj-colors';
@use 'styles/config/spacing';
@use 'styles/config/typography';

$popover-margin-xl: spacing.$unit;
$popover-margin-row-lg: spacing.$unit * 2;
$banner-line-height-small: 12px;

.popover {
  position: fixed;
  top: initial;
  bottom: 0;
  margin-left: $popover-margin-xl;
  margin-bottom: $popover-margin-xl;
  width: calc(100% - 2 *#{$popover-margin-xl});
  padding: 0 spacing.$unit;
  background: colors.$black;
  border: 1px solid colors.$border-light;
  border-radius: 5px;
  font-size: typography.$font-size-small;
  line-height: 130%;
  text-align: left;
  box-shadow: 0px 0px 10px colors.$border-dark;
  z-index: 2;
  color: colors.$text-color;

  .title {
    display: none;
  }

  .popoverContext {
    margin: calc(spacing.$unit * 0.75) calc(spacing.$unit * 5) calc(spacing.$unit * 0.75)
      spacing.$unit;
  }

  .text {
    max-width: 1200px;
    margin: auto;
    text-align: center;
    color: white;
    line-height: $banner-line-height-small;
  }

  .close {
    position: absolute;
    right: 14px;
    top: 8px;
    width: 12px;
    height: 18px;
    opacity: 1;
    cursor: pointer;
    color: white;
  }

  .close:before,
  .close:after {
    position: absolute;
    left: 8px;
    content: ' ';
    height: 18px;
    width: 3px;
    background-color: colors.$white;
  }

  .close:before {
    transform: rotate(45deg);
  }

  .close:after {
    transform: rotate(-45deg);
  }

  a {
    color: colors.$white;
  }

  @media (max-width: breakpoints.$lg) {
    width: calc(100% - $popover-margin-row-lg);
    margin: spacing.$unit;

    position: fixed;
    line-height: inherit;
    border-radius: spacing.$unit;
    padding: spacing.$unit * 3 spacing.$unit * 2;
    font-size: typography.$font-size-tiny;
    box-shadow: 0px 2px 2px proj-colors.$shadow, 0px 2px 4px proj-colors.$shadow,
      0px 1px 8px proj-colors.$shadow;

    .popoverContext {
      margin: 0;
    }

    .text {
      text-align: left;
    }
  }
}
