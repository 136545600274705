$font-size-xxx-large: 28px;
$font-size-xx-large: 24px;
$font-size-x-large: 20px;
$font-size-large: 18px;
$font-size-base: 16px;
$font-size-small: 14px;
$font-size-tiny: 12px;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$line-height-xx-large: 36px;
$line-height-x-large: 30px;
$line-height-large: 24px;
$line-height-medium: 21px;
$line-height-small: 18px;

:export {
  font-size-xxx-large: $font-size-xxx-large;
  font-size-xx-large: $font-size-xx-large;
  font-size-x-large: $font-size-x-large;
  font-size-large: $font-size-large;
  font-size-base: $font-size-base;
  font-size-small: $font-size-small;
  font-size-tiny: $font-size-tiny;

  font-weight-light: $font-weight-light;
  font-weight-normal: $font-weight-normal;
  font-weight-semi-bold: $font-weight-semi-bold;
  font-weight-bold: $font-weight-bold;

  line-height-xx-large: $line-height-xx-large;
  line-height-x-large: $line-height-x-large;
  line-height-large: $line-height-large;
  $line-height-medium: $line-height-medium;
  $line-height-small: $line-height-small;
}
