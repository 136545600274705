@use 'styles/config/breakpoints';
@use 'styles/config/spacing';
@use 'styles/config/typography';
@use '@assuranceiq/react-components/styles/colors';
@use './Slider.config.scss' as config;

$label-font-size-mobile: typography.$font-size-x-large;
$label-font-size-desktop: typography.$font-size-x-large + 4;

$mark-font-size-mobile: typography.$font-size-large;
$mark-font-size-desktop: typography.$font-size-x-large;

$line-height: 10px;

@mixin thumbBorder($width, $color) {
  box-shadow: 0px 0px 0px $width $color;
}

.root {
  text-align: center;
  margin-bottom: spacing.$unit * 1.5;

  :global .MuiSlider-thumb {
    @include thumbBorder(9px, config.$thumb-border-color);
  }

  :global .Mui-focusVisible {
    @include thumbBorder(9px, config.$thumb-border-color);
  }

  :global .Mui-active {
    @include thumbBorder(12px, config.$thumb-border-color);
  }

  :global .MuiSlider-markLabel {
    font-size: $mark-font-size-mobile;
  }
}

.label {
  color: colors.$primary;
  font-size: $label-font-size-mobile;
}

.placeholder {
  color: colors.$text-muted;
}

@include breakpoints.lg {
  .root {
    :global .MuiSlider-markLabel {
      font-size: $mark-font-size-desktop;
    }
  }

  .label {
    font-size: $label-font-size-desktop;
  }
}
