@use '@assuranceiq/react-components/styles/colors';
@use 'styles/config/breakpoints';
@use 'styles/config/typography';

.main {
  text-align: center;
  padding: 0;
  margin: 16px 0 0;
  font-size: typography.$font-size-tiny;
  @include breakpoints.md {
    font-size: typography.$font-size-base;
    margin: 32px 0 0;
  }
}

.link {
  font: inherit;
  vertical-align: baseline;
}
