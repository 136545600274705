@use '@assuranceiq/react-components/styles/colors';

@use 'styles/config/spacing';

$border-style: 1px solid colors.$border-light;
$error-margin-horizontal: spacing.$unit + 6;

.root {
  margin-bottom: 0;
}

.inputsRow {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.input:not(:last-child) {
  margin-right: spacing.$unit;
}

.error {
  color: colors.$error;
  margin-right: $error-margin-horizontal;
}
