@use 'styles/config/spacing';
@use 'styles/config/typography';
@use 'styles/config/breakpoints';

$end-icon-right: spacing.$unit * 3;

.root {
  :global .MuiButton-endIcon {
    position: absolute;
    right: $end-icon-right;
  }
}

.singleContinueButton {
  width: 252px;
}

.skipButton {
  min-height: 0;
  height: spacing.$unit * 3.25;
  margin-top: spacing.$unit * 3;
  margin-bottom: 0;
  font-size: typography.$font-size-base;
  font-style: normal;
  font-weight: typography.$font-weight-bold;
  line-height: 30px; /* 150% */
  cursor: pointer;

  @include breakpoints.sm {
    font-size: typography.$font-size-x-large;
    font-weight: typography.$font-weight-semi-bold;
  }
}
