@use '@assuranceiq/react-components/styles/colors';

@use 'styles/config/proj-colors';
@use 'styles/config/spacing';

$box-shadow: 0px 6px 8px proj-colors.$shadow, 0px 2px 12px proj-colors.$shadow,
  0px 2px 4px proj-colors.$shadow;

.root {
  text-align: center;

  // remove fieldset styling
  @at-root fieldset#{&} {
    border: none;
    padding: 0;
  }
}

.outlinedButton {
  border: none;
  text-decoration: underline;
  &:hover {
    color: colors.$primary-dark;
    background: none;
    border: none;
    text-decoration: underline;
  }
}

.highlighted {
  box-shadow: $box-shadow;
}
