@use 'styles/config/breakpoints';
@use 'styles/config/typography';

.text {
  font-size: typography.$font-size-large;
  line-height: inherit;

  @include breakpoints.md {
    font-size: typography.$font-size-xx-large;
  }
}

.subtext {
  @include breakpoints.xs-only {
    font-size: typography.$font-size-small;
    line-height: typography.$line-height-medium;
  }

  ul,
  ol {
    display: inline-block;
    text-align: left;
  }
}
