@use '@assuranceiq/react-components/styles/colors';
@use 'styles/config/typography';
@use 'styles/config/breakpoints';
@use 'styles/config/spacing';

.root {
  min-height: fit-content;
  height: spacing.$unit * 3.25;
  border: none;
  font-size: typography.$font-size-base;
  font-weight: typography.$font-weight-bold;

  &:hover {
    color: colors.$primary-dark;
    border: none;
  }

  &:focus {
    color: colors.$primary-dark;
    border: none;
  }

  @include breakpoints.sm {
    font-size: typography.$font-size-x-large;
    font-weight: typography.$font-weight-semi-bold;
  }
}

.icon {
  stroke: colors.$blue-100;
}
