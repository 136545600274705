@use 'styles/config/breakpoints';
@use 'plates/SimpleForm/simpleForm.scss';
@use 'components/PlatesBottomNavigation/platesBottomNavigation.scss';

@include breakpoints.md-down {
  .root {
    height: simpleForm.$next-button-container-height;

    &.platesBottomNavigation {
      height: platesBottomNavigation.$height;
    }
  }
}
