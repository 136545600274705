$xs: 0;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;

$short: 600px;
$tiny: 450px;

@mixin xs {
  @media (min-width: $xs) {
    @content;
  }
}

@mixin xs-down {
  @media (max-width: $xs) {
    @content;
  }
}

@mixin sm {
  @media (min-width: $sm) {
    @content;
  }
}

@mixin sm-down {
  @media (max-width: $sm) {
    @content;
  }
}

@mixin md {
  @media (min-width: $md) {
    @content;
  }
}

@mixin md-down {
  @media (max-width: $md) {
    @content;
  }
}

@mixin lg {
  @media (min-width: $lg) {
    @content;
  }
}

@mixin lg-down {
  @media (max-width: $lg) {
    @content;
  }
}


@mixin xl {
  @media (min-width: $xl) {
    @content;
  }
}

@mixin xs-only {
  @media (min-width: $xs) and (max-width: $sm) {
    @content;
  }
}
@mixin sm-only {
  @media (min-width: $sm) and (max-width: $md) {
    @content;
  }
}
@mixin md-only {
  @media (min-width: $md) and (max-width: $lg) {
    @content;
  }
}
@mixin lg-only {
  @media (min-width: $lg) and (max-width: $xl) {
    @content;
  }
}
@mixin xl-only {
  @media (min-width: $xl) {
    @content;
  }
}

@mixin short-only{
  @media(max-height: $short) and (min-height: $tiny) {
    @content;
  }
}

@mixin tiny-only{
  @media(max-height: $tiny) {
    @content;
  }
}
