@use 'styles/config/breakpoints';
@use 'styles/config/spacing';
@use 'styles/config/typography';
@use './platesBottomNavigation';
@use 'styles/config/button';

$footer-linkedin-icon-z-index: 1;

.root {
  justify-content: space-between;
  min-height: platesBottomNavigation.$height;
  width: 100%;

  @include breakpoints.xs-only {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: white;
    z-index: $footer-linkedin-icon-z-index + 1;
    padding: 0 spacing.units(2);
  }

  // Hide navigation to remove space from the bottom
  &:empty {
    display: none;
  }
}

.backBtn {
  &:only-child {
    margin-right: auto;
    margin-left: auto;
  }
}

.callBtn {
  width: platesBottomNavigation.$next-button-width;
  margin-left: auto;
}

.nextBtn {
  width: platesBottomNavigation.$next-button-width;
  margin-left: auto;
}

.skipBtn {
  @include button.underlined;
}
