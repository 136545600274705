@use '@assuranceiq/react-components/styles/colors';

@use 'styles/config/proj-colors';
@use 'styles/config/breakpoints';
@use 'styles/config/spacing';
@use 'styles/config/typography';

$progress-height: 9px;
$progress-bar-background-color: colors.$gray-60;
$progress-bar-progress-color: proj-colors.$gray-70;
$back-button-icon-size: 16px;

:export {
  backButtonIconArrowColor: colors.$text-muted;
}

.barContainer {
  display: flex;
  width: 100%;
  max-width: 300px;
  justify-content: center;
  align-items: center;
  padding: 0 spacing.$unit;
  margin-bottom: typography.$font-size-base - 1;

  .backButtonWrapper {
    display: flex;
    margin-right: typography.$font-size-base;
    color: colors.$blue-100;
    text-align: center;
    font-style: normal;
    text-decoration-color: colors.$blue-100;
    text-underline-offset: 2px;
    align-items: center;

    svg.backButtonIcon {
      stroke: colors.$blue-100;
      stroke-width: 1;
    }
    p.backButtonText {
      font-size: $back-button-icon-size;
      font-weight: typography.$font-weight-bold;
      margin-left: -3px;
      @media (min-width: breakpoints.$sm) {
        font-size: $back-button-icon-size + 2;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .startText {
    color: proj-colors.$gray-70;

    text-align: center;
    font-size: typography.$font-size-base;
    font-style: normal;
    font-weight: typography.$font-weight-bold;
    margin-right: typography.$font-size-large + 1;
    @media (min-width: breakpoints.$sm) {
      font-size: typography.$font-size-large;
    }
  }
}

.progressBar {
  width: 100%;
  background-color: $progress-bar-background-color;
  height: $progress-height;
  border-radius: $progress-height * 0.5;

  :global .MuiLinearProgress-bar {
    border-radius: $progress-height * 0.5;
    background-color: $progress-bar-progress-color;
  }

  @media (min-width: breakpoints.$sm) {
    height: $progress-height + 3;
  }
}
