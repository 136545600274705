@use 'styles/config/typography';
@use '@assuranceiq/react-components/styles/colors';
@use 'styles/config/spacing';

.root {
  :global .MuiTypography-h3 {
    font-size: typography.$font-size-large;
    line-height: typography.$line-height-large;
    color: colors.$text-color;
    margin-bottom: 0;
  }
}

.navigation {
  padding: 0 spacing.units(3);
  position: static; // Overrides position: fixed on mobile
}
