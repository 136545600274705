@use '@assuranceiq/react-components/styles/colors';
@use 'styles/config/typography';
@use 'styles/config/spacing';
@use 'styles/config/breakpoints';

$drag-handler-color: colors.$gray-50;
$drag-handler-width: 44px;
$drag-handler-height: 4px;
$drag-handler-radius: 4px;

$root-mobile-radius: 28px;

.root {
  @include breakpoints.md-down {
    padding-top: 30%;

    :global .MuiDialog-paper {
      border-radius: $root-mobile-radius $root-mobile-radius 0 0;
    }
  }
}

.title {
  font-size: typography.$font-size-base;
  line-height: typography.$line-height-large;
  font-weight: typography.$font-weight-bold;
  text-align: center;
  margin-top: spacing.units(2);

  @include breakpoints.md {
    margin-top: 0;
    font-size: typography.$font-size-x-large;
    line-height: typography.$line-height-x-large;
  }
}

.a11yTitle {
  display: none;
}

.dragHandler {
  cursor: pointer;
  width: $drag-handler-width;
  height: $drag-handler-height;
  margin: auto;
  border-radius: $drag-handler-radius;
  background-color: $drag-handler-color;
}

.closeIcon {
  position: absolute;
  right: spacing.units(1.5);
  top: spacing.units(1.5);
}

.content {
  color: colors.$text-muted;
}
