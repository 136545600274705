@use '@assuranceiq/react-components/styles/colors';

@use 'styles/config/spacing';
@use 'styles/config/typography';

$option-border-radius: 5px;
$label-subtext-padding: 6px;
$error-icon-size: typography.$font-size-base;

.root {
  padding: spacing.$unit;
  margin: 0;
  border-bottom: 1px solid colors.$gray-50;

  &:last-child {
    border-bottom: none;
  }

  &:focus-within {
    outline: 1px solid colors.$active-light;
    outline-offset: 1px;
  }

  :global .MuiTypography-root {
    width: 100%;
  }

  :global .PrivateSwitchBase-root {
    color: colors.$blue-100;
    align-self: flex-start;
  }
}

.error {
  border-color: colors.$error;
  background-color: colors.$error-light-accent;
  color: colors.$error;

  .subtext {
    color: colors.$text-color;
  }

  &:hover {
    border-color: colors.$error;
  }
}

.checked {
  border-color: colors.$active-light;
  background-color: colors.$active-light-accent;
}

.label {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &.withSubtext {
    padding-top: $label-subtext-padding;
  }
}

.labelText {
  color: colors.$text-color;
  font-size: typography.$font-size-large;
}

.subtext {
  margin-bottom: spacing.units(1);
  margin-top: spacing.units(1.7);
}

.errorIcon {
  width: $error-icon-size;
  height: $error-icon-size;
  position: absolute;
  right: spacing.units(2);
  left: auto;
  top: spacing.units(1);
}
