@use '@assuranceiq/react-components/styles/colors';
@use 'styles/config/_proj-colors.scss' as proj_colors;
@use 'components/SimpleForm/Input/input';
@use 'styles/config/spacing';
@use 'styles/config/typography';

$error-icon-size: typography.$font-size-base;
$error-margin-horizontal: spacing.$unit + 6;

.root {
  text-align: center;
  max-width: input.$max-width;
  margin: auto;
  margin-bottom: spacing.$unit * 1.5;

  &:last-child {
    margin-bottom: 0;
  }
}

.standard {
  fieldset {
    border-color: proj_colors.$gray-45;
  }

  &.width_64 {
    max-width: 64px;
  }

  &.width_84 {
    max-width: 84px;
  }
}

.inlineLabeledInput {
  width: 100%;

  fieldset {
    border: 0;
  }
}

.error {
  color: colors.$error;
  margin-left: $error-margin-horizontal;
  margin-right: $error-margin-horizontal;
}

.errorIcon {
  width: $error-icon-size;
  height: $error-icon-size;
}

.valid {
  fieldset {
    border-color: colors.$info-light;
  }
}

.suffixText {
  flex: 0 0 auto;
  align-items: center;
  display: flex;
  padding-left: spacing.$unit;
}

.inputRow {
  display: flex;
}
