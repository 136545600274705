@use '@assuranceiq/react-components/styles/colors';

@use 'styles/config/breakpoints';
@use 'styles/config/spacing';
@use 'styles/config/typography';

.root {
  :global .MuiButtonBase-root {
    border-radius: spacing.$unit;
  }
}

.heading {
  text-align: center;
  padding-top: spacing.$unit * 5;
  padding-bottom: spacing.$unit * 5.5;
  color: colors.$white;

  @include breakpoints.xs-only {
    padding-top: spacing.$unit * 3;
    padding-bottom: spacing.$unit * 3;
  }

  .text {
    font-weight: typography.$font-weight-bold;
  }

  .text,
  .subText {
    margin: 0;
    line-height: 1.4;
    font-size: typography.$font-size-xx-large;

    @include breakpoints.xs-only {
      font-size: typography.$font-size-base;
    }
  }

  &.withSubText {
    padding-top: spacing.$unit * 4;
    padding-bottom: spacing.$unit * 4;

    @include breakpoints.xs-only {
      padding-top: spacing.$unit * 2;
      padding-bottom: spacing.$unit * 2;

      .text,
      .subText {
        font-size: typography.$font-size-base;
      }
    }
  }
}
