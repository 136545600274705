@use '@assuranceiq/react-components/styles/colors';
@use 'styles/config/spacing';

$radio-group-gap: spacing.$unit * 1.5;

.root {
  text-align: left;
  margin-bottom: spacing.$unit * 1.5;
}

.radioGroup {
  gap: $radio-group-gap;
}

.label {
  margin-bottom: spacing.$unit;
  color: colors.$text-color;
}

.error {
  color: colors.$error;
}

.subtextContainer {
  margin-top: spacing.$unit * 0.5;
  margin-bottom: spacing.$unit * 1.5;
  background-color: colors.$gray-25;
  padding: spacing.$unit;
}
